import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingOverlay } from '../../components/loading/Loading';
import { useAPI } from '../../context/APIContext';
import EmailSending from './EmailSending';
import EmailVerifying from './EmailVerifying';
import MemberCreating from './MemberCreating';
import apiUri from '../../config/Api.js';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '} 趣找屋 {' '} {new Date().getFullYear()} {'.'}
        </Typography>
    );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export const Register = () => {
    const { loading, handleLoginStatus, post } = useAPI();
    const navigate = useNavigate();
    const [step, setStep] = useState({
        email_sending: true,
        email_verifying: false,
        member_creating: false
    });
    const [currentStep, setCurrentStep] = useState('email_sending');
    const [email, setEmail] = useState("");
    const [verificationCode, setVerificationCode] = useState("");
    const [memberInfo, setMemberInfo] = useState({
        name: "",
        pwd: "",
        confirm_pwd: ""
    });

    // 輪播設置
    const settings = {
        // dots: true, // 顯示圓點導航
        infinite: true, // 無限循環
        speed: 500, // 切換速度
        slidesToShow: 1, // 每次顯示一張圖片
        slidesToScroll: 1, // 每次滾動一張圖片
        autoplay: true, // 自動播放
        autoplaySpeed: 3000, // 自動播放間隔
    };

    // 圖片數據
    const images = [
        '/ad/step.webp',
        '/ad/water_electricity.webp'
    ];

    const handleClickBreadcrumbs = (event) => {
        event.preventDefault();
        event.stopPropagation();  // 阻止事件冒泡
        const id = event.currentTarget.id;
        setCurrentStep(id);
        if (id === "email_sending") {
            setStep({
                email_sending: true,
                email_verifying: false,
                member_creating: false
            });
        }
        else if (id === "email_verifying") {
            setStep({
                email_sending: true,
                email_verifying: true,
                member_creating: false
            });
        }
        else {
            setStep({
                email_sending: true,
                email_verifying: true,
                member_creating: true
            });
        }
    }

    const handleSendEmail = (event) => {
        SendEmail();
    }

    const SendEmail = async () => {
        await post(apiUri.SendEmail,
            { email: email, type: 1 },
            (resData) => {
                setCurrentStep("email_verifying");
                setStep({
                    email_sending: true,
                    email_verifying: true,
                    member_creating: false
                });
            });
    }

    const handleVerifyEmail = (event) => {
        VerifyEmail();
    }

    const VerifyEmail = async () => {
        await post(apiUri.VerifyEmail,
            { email: email, verification_code: verificationCode, type: 1 },
            (resData) => {
                setCurrentStep("member_creating");
                setStep({
                    email_sending: true,
                    email_verifying: true,
                    member_creating: true
                });
            });
    }

    const handleCreateMember = (event) => {
        CreateMember();
    }

    const CreateMember = async () => {
        const resData = await post(apiUri.CreateMember,
            { email: email, verification_code: verificationCode, name: memberInfo.name, pwd: memberInfo.pwd });

        if (resData.code === 0) {
            handleLoginStatus(true, resData);
        }
        else {
            navigate('/Register', { replace: false });
        }
    }

    return (
        <>
            {loading && <LoadingOverlay />}
            <ThemeProvider theme={defaultTheme}>
                <Grid container component="main" sx={{ height: 'calc(100vh - 60px)' }}>
                    <Grid item xs={false} sm={4} md={7}>
                        <Slider {...settings}>
                            {images.map((image, index) => (
                                <div key={index}>
                                    <img
                                        src={image}
                                        alt={`Slide ${index + 1}`}
                                        style={{
                                            width: "100%",
                                            height: "calc(100vh - 60px)",
                                            borderRadius: "0px",
                                        }}
                                    />
                                </div>
                            ))}
                        </Slider>
                    </Grid>
                    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                        <Box
                            sx={{
                                my: 2,
                                mx: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                                <LockOutlinedIcon />
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                註冊
                            </Typography>
                            <Box
                                sx={{
                                    mt: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'left',
                                }}
                            >
                                <Breadcrumbs aria-label="breadcrumb">
                                    {step.email_sending && step.email_sending === true &&
                                        <Link underline="hover" color="inherit" href="#" id="email_sending" onClick={handleClickBreadcrumbs}>
                                            信箱
                                        </Link>
                                    }
                                    {step.email_verifying && step.email_verifying === true &&
                                        <Link underline="hover" color="inherit" href="#" id="email_verifying" onClick={handleClickBreadcrumbs}>
                                            驗證碼
                                        </Link>
                                    }
                                    {step.member_creating && step.member_creating === true &&
                                        <Link underline="hover" color="inherit" href="#" id="member_creating" onClick={handleClickBreadcrumbs}>
                                            會員資料
                                        </Link>
                                    }
                                </Breadcrumbs>
                            </Box>
                            {currentStep === 'email_sending' &&
                                <EmailSending
                                    email={email} onSendEmail={handleSendEmail} setEmail={setEmail}
                                />
                            }
                            {currentStep === 'email_verifying' &&
                                <EmailVerifying
                                    verificationCode={verificationCode} onVerifyEmail={handleVerifyEmail} setVerificationCode={setVerificationCode}
                                />
                            }
                            {currentStep === 'member_creating' &&
                                <MemberCreating
                                    memberInfo={memberInfo} onCreateMember={handleCreateMember} setMemberInfo={setMemberInfo}
                                />
                            }
                            <Box sx={{ width: '100%' }}>
                                <Copyright sx={{ mt: 5 }} />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </ThemeProvider>
        </>
    );
}

export default React.memo(Register);
