import AccountCircle from '@mui/icons-material/AccountCircle';
import { Box, Avatar } from '@mui/material';
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from "@mui/material/Toolbar";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { apiUris } from './config/Api';
import { useAPI } from './context/APIContext';
import { bannerHeight, bannerColor } from './css/themeStyles';

const appBarStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: bannerHeight,
  backgroundColor: bannerColor
};

const menuStyle = {
  display: 'flex',
  flexGrow: 1
}

const accountStyle = {
  display: 'flex',
};

const HeaderMenu = () => {
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { loginStatus, handleLoginStatus, post } = useAPI();
  const navigate = useNavigate();

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
  }

  const logout = async () => {
    const res = await post(apiUris.logout);
    //不論 Server 是否註銷 token 成功，前端都進行登出
    handleLoginStatus(false);
  }

  return (
    <AppBar sx={appBarStyle}>
      <Toolbar>
        <Box sx={menuStyle}>
          <Button color="inherit" component={Link} to="/">
            <Avatar
              src="/logo.webp" // Change this to your image path
              alt="logo"
              sx={{ width: 50, height: 50 }}
            />
          </Button>
          <Button color="inherit" component={Link} to="/">首頁</Button>
          <Button color="inherit" component={Link} to="/About">關於</Button>
          {/* <Button color="inherit" component={Link} onClick={props.handleScrollToEnvChip} to="/">機能</Button>
            <Button color="inherit" component={Link} onClick={props.handleScrollToPriceChip} to="/">實價登錄</Button> */}
          {/* <Button color="inherit" component={Link} to="/TempEnv">待新增機能</Button> */}
          {/* <Button color="inherit" component={Link} to="/PositionDisplay">版面測試</Button> */}
        </Box>
        <Box sx={accountStyle}>
          {/* <Button color="inherit" component={Link} to="/Pricing">
              <img width="30px" src="/cart-50.png"></img>
            </Button> */}
          {
            loginStatus == true
              ?
              <div>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem component={Link} to="/MemberInfo">帳戶</MenuItem>
                  <MenuItem onClick={handleLogout}>登出</MenuItem>
                </Menu>
              </div>
              : <Button color="inherit" component={Link} to="/Login">登入</Button>
          }
        </Box>
      </Toolbar>
    </AppBar>
  );
}


export default React.memo(HeaderMenu);